body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.swiper {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.swiper-container{
  width: 50%!important;
}

@media only screen and (min-width:1202px){
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width:95%;
    max-width: 95%;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
   
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    background-color: #0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.css-1iji0d4{
    background-color: #0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .MuiAccordionDetails-root.sc-cCzKKE.hvxwUo.css-u7qq7e{
    margin:0!important;
  }
  .css-67l5gl{
    margin:0!important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    height: 60px!important;
    min-height: 60px!important;
    max-height: 60px!important;
    margin-top:0!important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin:0!important;
  }
  
  /* Carousel Styles */
  .thumbs-wrapper.axis-vertical{
    align-items: center;
    text-align: center;
    margin:0!important;
    margin-left: -10%!important;
    margin-bottom: 5%!important;
    cursor: pointer;
  }
  .carousel .carousel-status{
    display: none;
  }
  .carousel .control-dots{
    display: none!important;
  }
  .custom-arrow-left{
    background-color: #0C1B2A;
    height:5.5vh;
    width:5.5vh;
    position:absolute;
    left:0;
    top:37.25vh;
    text-align: center;
    z-index:120;
    cursor:pointer;
    opacity:1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-arrow-right{
    background-color: #0C1B2A;
    height:5.5vh;
    width:5.5vh;
    position:absolute;
    right:0;
    top:37.25vh;
    text-align: center;
    cursor:pointer;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-image-magnifier-zoom-container {
    position: relative;
    cursor: zoom-in !important;
  }
  
  .react-image-magnifier-zoom-lens {
    cursor: zoom-in !important;
  }
  .sc-hnmMDg .kvAfWh{
    cursor:zoom-in!important;
  }
}

@media only screen and (max-width:1201px) and (min-width:542px){
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width:100%;
    max-width: 100%;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.css-1iji0d4{
    background-color: #0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    background-color:#0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .MuiAccordionDetails-root.sc-cCzKKE.hvxwUo.css-u7qq7e{
    margin:0!important;
  }
  .css-67l5gl{
    margin:0!important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    height: 60px!important;
    min-height: 60px!important;
    max-height: 60px!important;
    margin-top:0!important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin:0!important;
  }
  /* Carousel Styles */
  .thumbs-wrapper.axis-vertical{
    align-items: center;
    text-align: center;
    margin:0!important;
    margin-left: -10%!important;
    margin-bottom: 5%!important;
  }
  .carousel .carousel-status{
    display: none;
  }
  .carousel .control-dots{
    display: none!important;
  }
  .custom-arrow-left{
    background-color: #0C1B2A;
    height:5.5vh;
    width:5.5vh;
    position:absolute;
    left:0;
    top:17vh;
    text-align: center;
    z-index:120;
    cursor:pointer;
    opacity:1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-arrow-right{
    background-color: #0C1B2A;
    height:5.5vh;
    width:5.5vh;
    position:absolute;
    right:0;
    top:17vh;
    text-align: center;
    cursor:pointer;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width:541px) and (min-width:320px){
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    width:100%;
    max-width: 100%;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters.css-1iji0d4{
    background-color: #0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root{
    background-color:#0C1B2A!important;
    color:white!important;
    height: 60px!important;
    min-height: 60px!important;
  }
  .MuiAccordionDetails-root.sc-cCzKKE.hvxwUo.css-u7qq7e{
    margin:0!important;
  }
  .css-67l5gl{
    margin:0!important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    height: 60px!important;
    min-height: 60px!important;
    max-height: 60px!important;
    margin-top:0!important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    margin:0!important;
  }

   /* Carousel Styles */
   .thumbs-wrapper.axis-vertical{
    align-items: center;
    text-align: center;
    margin:0!important;
    margin-left: -10%!important;
    margin-bottom: 5%!important;
  }
  .carousel .carousel-status{
    display: none;
  }
  .carousel .control-dots{
    display: none!important;
  }
}


